import { createSignal } from "solid-js";
import PlutoChatComponent from "./pluto_chat_component";
import { ChatDetails, GiftCardDetails } from "~/server/types/pluto";

export type PlutoExisitingChatComponentProps = {
  giftDetails: GiftCardDetails | undefined;
  chatDetails: ChatDetails | undefined;
};

export default function PlutoExisitingChatComponent(
  props: PlutoExisitingChatComponentProps
) {
  const [isFullScreenModalOpen, setIsFullScreenModalOpen] = createSignal(false);

  return (
    <PlutoChatComponent
      isFullScreenModalOpen={isFullScreenModalOpen}
      setIsFullScreenModalOpen={setIsFullScreenModalOpen}
      giftDetails={props.giftDetails}
      chatDetails={props.chatDetails}
    />
  );
}
