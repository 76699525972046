import { cache, createAsync, useParams } from "@solidjs/router";
import { Accessor, Show, Suspense } from "solid-js";
import PlutoExisitingChatComponent from "~/components/pluto/chat/pluto_exisiting_chat_component";
import {
  getPlutoChatDetailsRouteData,
  PlutoChatDetailsRouteData,
} from "~/server/data/pluto_chat_id_route_data";
import { DottedLoader } from "~/widgets/loader";

export default function ChatPage() {
  const params = useParams();
  const getPlutoChatDetailsRouteData$C = cache(
    getPlutoChatDetailsRouteData,
    `pluto_chat_details_${params.chatId}`
  );

  const routeData: Accessor<PlutoChatDetailsRouteData | undefined> =
    createAsync(() => getPlutoChatDetailsRouteData$C(params.chatId), {
      deferStream: true,
    });

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <Show when={routeData()?.chatDetails?.id == params.chatId}>
        <PlutoExisitingChatComponent
          giftDetails={routeData()?.giftDetails}
          chatDetails={routeData()?.chatDetails}
        />
      </Show>
    </Suspense>
  );
}
