"use server";

import {
  getConversationById,
  getGiftByConversationId,
} from "../apis/server_apis";
import { ChatDetails, GiftCardDetails } from "../types/pluto";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { redirect } from "@solidjs/router";
import { APIError } from "~/utils/fetch";

export type PlutoChatDetailsRouteData = {
  chatDetails?: ChatDetails;
  giftDetails?: GiftCardDetails;
};

export const getPlutoChatDetailsRouteData = async (
  chatId: string
): Promise<PlutoChatDetailsRouteData> => {
  let giftDetails: GiftCardDetails | undefined;
  let chatDetails: ChatDetails | undefined;

  let sid = getCookie(Cookie.SessionId);

  if (!sid) {
    throw redirect("/pluto/login");
  }

  try {
    chatDetails = await getConversationById(chatId, {
      throwAuthError: true,
    });

    if (chatDetails.status === "COMPLETED") {
      let g = await getGiftByConversationId(chatDetails.id, {
        throwAuthError: true,
      });
      if (g.status === "COMPLETED") {
        giftDetails = g;
      }
    }
  } catch (error) {
    if (error instanceof APIError && error.code === 401) {
      throw redirect("/pluto/login");
    }
    throw error;
  }

  return { chatDetails, giftDetails };
};
